import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo
      title="お問い合わせ"
    />

    <section className="bg-secondry-light p-16">
      <div className="container max-w-5xl mx-auto">
        <div className="flex justify-around">
          <h1 className="text-2xl text-white font-bold leading-none mb-3">お問い合わせ</h1>
        </div>
      </div>
    </section>

    <section className="bg-base-main p-8">
      <div className="max-w-5xl mx-auto">

        <div className="flex flex-wrap">
          <div className="">
            <Link to={`/`}>
              <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
            </Link>
            <span className="mx-4 text-sm">></span>
            <Link to={`/contact/`}>
              <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">お問い合わせ</button>
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-base-main p-8">
      <div className="container max-w-5xl mx-auto">

        <div className="flex justify-around">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd_4tWrujEVJYP2cD3cQar7qX7VJIlV1BrMMSMYFvJ9PGheFg/viewform?embedded=true" width="640" height="1838" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
        </div>

      </div>
    </section>
  </Layout>
)

export default ContactPage
